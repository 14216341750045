import React, { useState } from 'react';
import ContentWrapper from '../components/ContentWrapper';
import SoppelSorteringImage from'../images/portfolio/HogskolenVolda/HeroImage.jpg';
import NRKVideo from'../images/portfolio/NRK/nrk_video.png';
import RotsetHornetImage from '../images/portfolio/Rotsethornet/HeroImage.jpg';
import Skolekinodagen from '../images/portfolio/FilmOgKino/video-thumbnail.jpg';
import FiletSkolenImage from '../images/portfolio/FiletSkolen/HeroImage.jpg';
import CreazaImage from '../images/portfolio/Creaza/HeroImage.jpg';
import AlphabetPlanetImage from '../images/portfolio/Creaza/AlfabetPlaneten/thumbnail.jpg';
import IPR from '../images/portfolio/IPR/tannpuss-2.png';
import Title from '../images/titles/portefoelje.svg';
import Refinansiering from '../images/portfolio/Sparebank1/refinansiering.png';
import RoomOfMirrorsThumbnail from '../images/portfolio/Tristious/room-of-mirrors.png';

//Posters
import DodensPraktikantPoster from '../images/portfolio/DodensPraktikant/poster.png'
import PulverHeksaPoster from '../images/portfolio/PulverHeksa/poster.png'
import VDPoster from '../images/portfolio/ViciousDogs/poster.png'
import SantaIsDone from '../images/portfolio/CrispinGlover/poster.png'
import AlienPlanetsPoster from '../images/portfolio/AlienPlanets/poster.png'

import { Link } from 'gatsby';
import '../styles/portfolio.css';


const Poster = ({projectName, imgSrc}) => {
  return <Link to={`/portfolio/${projectName}`}>
     <div className="max-w-sm sm:max-w-md md:max-w-3xs rounded shadow-md">
      <img className="max-w-sm sm:max-w-md md:max-w-3xs rounded" src={imgSrc} />
    </div>
  </Link>
}

const Work = () => {
    const clientProjects = [
      {
        image: RoomOfMirrorsThumbnail,
        client: "Tristious",
        title: "Metallica",
        projectname: "tristious"
      },
      {
        image: Refinansiering,
        client: "SpareBank 1",
        title: "Refinansiering og forbukslån" ,
        projectname: "sparebank-1"
      },
      {
        image: IPR,
        client: "IPR (WeMake)",
        title: "Sinte barn og sinte voksne" ,
        projectname: "IPR"
      },
      {
        image: NRKVideo,
        client: "",
        title: "NRK" ,
        description: <div>
          <p>“Andreas Wahl har alltid drømt om å få være vektløs som en astronaut. <br /> Nå har han fått sjansen til å forklare hvorfor vektløshet <br /> er det samme som å være i fritt fall.”</p>
        </div>,
        projectname: "nrk"
      },
      {
        image: AlphabetPlanetImage,
        client: "Creaza",
        title: "Alfabetplaneten" ,
        projectname: "alfabetplaneten"
      },
      {
        image: Skolekinodagen,
        client: "Film og Kino",
        title: "Den Store Skolekinodagen" ,
        projectname: "film-og-kino"
      },
      {
        image: CreazaImage,
        client: "Creaza",
        title: "Kreative filmer for nettside",
        description: "Vil du lære på en underholdene og kreativ måte?",
        projectname: "creaza"
      },
      {
        image: RotsetHornetImage,
        client: "Oclin",
        title: "Rotsethornet",
        description: "Få med deg hva Rothsethornet har å si!",
        projectname: "rotsethornet"
      },
      {
        image: FiletSkolenImage,
        client: "Cod Cluster",
        title: "Filetskolen modul 2" ,
        projectname: "filetskolen"
      },
      {
        image: SoppelSorteringImage,
        client: "Høgskulen volda",
        title: "Søppelsortering" ,
        projectname: "hogskolenvolda"
      },
    ];

   

    return  <ContentWrapper>
        <div className="mb-8 mt-20">
        <p className="text-center text-3xl mb-4">Tidligere oppdrag</p>
        <div className="flex flex-wrap justify-center gap-3">
          
          {clientProjects.map((project) => {
            return <PortfolioProject
              image={project.image}
              client={project.client}
              title={project.title}
              description={project.description}
              projectname={project.projectname}
            /> 
          })}
        </div>
       
        </div>
        
      
      </ContentWrapper>

}

const PortfolioProject = ({ image, client, title, description, projectname}) => {
  return <Link to={`/portfolio/${projectname}`} className="">
    <div className="p-4 mb-4 max-w-md" >
    <div className="overflow-hidden rounded-t-md max-w-xs md:max-w-md max-h-64 md:h-64">
      <img className="max-w-xs md:max-w-md" src={image} />
    </div>

    <p className="mt-7">{client}</p>
    <p className="text-xl mt-1 mb-2">{title}</p>
    {description && <p className="mb-8 max-w-xs md:max-w-md">{description}</p>}
     <p className="underline text-tb-orange  cursor-pointer">Les mer</p>
  </div></Link>
}



export default Work;


